import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Line } from '@ant-design/plots';
import { Card } from 'antd';
import { Empty, Row, Select, Spin, Radio, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';
import useSwr from '@src/hooks/useSwr';
import ParametrizedDatePicker from '@src/components/DatePicker/DatePicker';

const ChartBaseEvolution = () => {
  const { t } = useTranslation('lorawan');
  const { keycloak } = useContext(KeycloakContext);
  const customerId = keycloak.idTokenParsed?.customerId;

  const [selectedContract, setSelectedContract] = useState();
  const [periodType, setPeriodType] = useState('daily');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { data: dataBaseUse } = useSwr(
    selectedContract && startDate && endDate
      ? '/service-proxy/lorawanDevices/basePeak'
      : null,
    {
      assetNumber: selectedContract,
      periodType,
      startDate,
      endDate,
    },
  );

  const { data: dataContracts } = useSwr(
    customerId ? '/service-proxy/contracts' : null,
    {
      customerId,
      product: 'LORAWAN',
    },
  );

  const contractOptions = useMemo(
    () =>
      dataContracts && Array.isArray(dataContracts)
        ? dataContracts?.map(({ planName, assetNumber }) => ({
            value: assetNumber,
            label: `${assetNumber} - ${planName}`,
          }))
        : [],
    [dataContracts],
  );

  const handleDate = useCallback(
    dateString => {
      const isDaily = periodType === 'daily';
      const dateFormat = isDaily ? 'YYYY-MM-DD' : 'YYYY-MM';

      const dateInMoment = moment(dateString, dateFormat).locale(
        i18next.language,
      );

      return isDaily
        ? dateInMoment.format('L')
        : dateInMoment.format('MMM YYYY')?.toUpperCase();
    },
    [periodType],
  );

  const treatedData = useMemo(
    () =>
      dataBaseUse?.lista?.map(item => ({
        date: handleDate(item.dtMovimentacao),
        value: item.nuQtd,
      })),
    [dataBaseUse?.lista, handleDate],
  );

  const config = {
    data: treatedData ?? [],
    xField: 'date',
    yField: 'value',
    meta: {
      value: {
        alias: t('devices'),
      },
    },
    point: {
      size: 4,
      shape: 'circle',
      style: {
        fill: '#5B8FF9',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
  };

  const handleDateChange = useCallback(date => {
    if (!date) return;
    const [firstDay, lastDay] = date;
    setStartDate(firstDay.format('YYYYMMDD'));
    setEndDate(lastDay.format('YYYYMMDD'));
  }, []);

  const disabledDate = useCallback(currentDate => {
    const today = moment().startOf('day');

    return currentDate && today < currentDate.startOf('day');
  }, []);

  return (
    <Card
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {t('baseEvolution')}
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            onChange={e => setPeriodType(e.target.value)}
            defaultValue="daily"
            value={periodType}
          >
            <Radio.Button value="daily" style={{ zIndex: 0 }}>
              {t('daily')}
            </Radio.Button>
            <Radio.Button value="monthly" style={{ zIndex: 0 }}>
              {t('monthly')}
            </Radio.Button>
          </Radio.Group>
        </div>
      }
      style={{ width: '100%' }}
      bodyStyle={{
        padding: 24,
      }}
    >
      <Row gutter={[16, 16]} style={{ marginBottom: 40 }}>
        <Col lg={12} xs={24}>
          <Select
            showSearch
            optionFilterProp="label"
            options={contractOptions ?? []}
            loading={!contractOptions}
            onChange={setSelectedContract}
            disabled={!contractOptions || contractOptions?.length === 0}
            placeholder={t('selectAContract')}
            style={{ width: '100%' }}
          />
        </Col>
        <Col lg={12} xs={24}>
          <ParametrizedDatePicker
            disabledDate={disabledDate}
            style={{ width: '100%' }}
            onChange={handleDateChange}
            range
          />
        </Col>
      </Row>
      {!treatedData || treatedData?.length === 0 ? (
        <>
          {selectedContract && startDate && endDate && !treatedData ? (
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                padding: '54px 8px',
              }}
            >
              <Spin />
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </>
      ) : (
        <Line {...config} />
      )}
    </Card>
  );
};

export default ChartBaseEvolution;

import React, { useCallback, useState } from 'react';
import { Input, Button, Tooltip } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import SearchUploader from '../SearchUploader/index';
import { useTranslation } from 'react-i18next';
const { Search } = Input;

export const InputSearchFile = ({
  valueOfItem,
  setSelectedThirdValue,
  selectedThirdValue,
  clearSearchParams,
  selectedSecondValue,
  onSearch,
}) => {
  const { t } = useTranslation('inventory');

  const [modalSearchUploaderVisible, setModalSearchUploaderVisible] =
    useState(false);

  const searchUploadedDevicesApply = useCallback(
    uploadedDevices => {
      switch (valueOfItem.name) {
        case 'msisdn':
          setSelectedThirdValue(
            uploadedDevices.map(device => device.msisdn).join(','),
          );
          break;
        case 'iccid':
          setSelectedThirdValue(
            uploadedDevices.map(device => device.iccid).join(','),
          );
          break;
        case 'imsi':
          setSelectedThirdValue(
            uploadedDevices.map(device => device.imsi).join(','),
          );
          break;
      }
    },
    [setSelectedThirdValue, valueOfItem],
  );

  const suffixUpload = useCallback(() => {
    return (
      <Tooltip title={t('file-search')}>
        <Button
          type="link"
          icon={<UploadOutlined />}
          size={'small'}
          style={{ height: 22, width: 22, alignItems: 'center' }}
          onClick={() => {
            setModalSearchUploaderVisible(true);
          }}
        />
      </Tooltip>
    );
  }, [t]);

  return (
    <>
      <Search
        suffix={suffixUpload()}
        onSearch={value => onSearch(value)}
        disabled={!selectedSecondValue}
        style={{ width: '100%' }}
        onChange={({ target: { value }, nativeEvent: { type } }) => {
          const isEmptyAndClickOnClear = value === '' && type === 'click';
          if (isEmptyAndClickOnClear) {
            clearSearchParams([`${valueOfItem?.name}`]);
            onSearch(undefined);
          }
          setSelectedThirdValue(value);
        }}
        value={selectedThirdValue}
        allowClear
      />
      <SearchUploader
        visible={modalSearchUploaderVisible}
        onClose={() => setModalSearchUploaderVisible(false)}
        columnName={valueOfItem?.name}
        setList={searchUploadedDevicesApply}
      />
    </>
  );
};

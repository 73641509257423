import { Select, Input, Row, Col, Typography, DatePicker, Tag } from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SingleRangePicker from '@src/components/DatePicker/SingleRangePicker/SingleRangePicker';
import { useTranslation } from 'react-i18next';
import SliderRange from '@src/components/SliderRange/SliderRange';
import { InputSearchFile } from '@src/components/InputSearchFile/index';
import SessionContext from '@src/store/SessionContext/SessionContext';
import SelectContextItem from '@src/components/SelectContext/SelectContextItem/SelectContextItem';
import moment from 'moment';
import useSwr from '@src/hooks/useSwr';
import KeycloakContext from '@src/store/KeycloakContext/KeycloakContext';

const { Search } = Input;

export const QueryItem = ({
  setSearchParams,
  clearSearchParams,
  valueOfItem,
  onChangeSearchUploader,
}) => {
  const { t: translate } = useTranslation('inventory');

  const { customer } = useContext(SessionContext);
  const { keycloak } = useContext(KeycloakContext);

  const selectedSecondValue = useMemo(
    () => (valueOfItem?.rules ? valueOfItem.rules[0] : undefined),
    [valueOfItem],
  );

  const { data: newApnData } = useSwr(
    valueOfItem.name === 'apn' || valueOfItem.name === 'provisionedApn'
      ? `/service-proxy/apns-customer/${keycloak?.idTokenParsed?.customerId}`
      : null,
  );

  const apnData = useMemo(() => {
    if (!newApnData) return [];
    const uniqueApns = new Set(newApnData);
    return Array.from(uniqueApns).map(apn => ({
      label: apn,
      value: apn,
    }));
  }, [newApnData]);

  const formatItem = useMemo(
    () => (valueOfItem?.rules?.length === 2 ? valueOfItem.rules[1] : undefined),
    [valueOfItem],
  );

  const [selectedThirdValue, setSelectedThirdValue] = useState(
    valueOfItem.defaultValue || undefined,
  );

  const mapOptions = useCallback(
    options =>
      options?.map(item => ({
        value: item.id,
        label: (
          <SelectContextItem id={item.id} cpfCnpj={item.cpfCnpj} {...item} />
        ),
        filterProps: `${item.name};${item.tradingName};${item.cpfCnpj};${item.id}`,
      })),
    [],
  );

  useEffect(() => {
    setSelectedThirdValue(valueOfItem.defaultValue);
  }, [valueOfItem]);

  const handleSearchUploader = useCallback(
    value => {
      const treatedValue =
        value !== '' ? value?.replace(/\s+/g, '') : undefined;
      const splitValue = treatedValue?.split(',');
      const valueObj = {
        [valueOfItem?.name]: splitValue,
      };
      if (splitValue?.length <= 1) {
        setSearchParams(valueObj);
      } else {
        clearSearchParams([valueOfItem?.name]);
        onChangeSearchUploader(valueObj);
      }
    },
    [
      clearSearchParams,
      onChangeSearchUploader,
      setSearchParams,
      valueOfItem?.name,
    ],
  );

  return (
    <Row align="bottom">
      {selectedSecondValue === 'Equals' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          {valueOfItem.name === 'iccid' ||
          valueOfItem.name === 'imsi' ||
          valueOfItem.name === 'msisdn' ? (
            <InputSearchFile
              valueOfItem={valueOfItem}
              setSelectedThirdValue={setSelectedThirdValue}
              selectedThirdValue={selectedThirdValue}
              clearSearchParams={clearSearchParams}
              selectedSecondValue={selectedSecondValue}
              onSearch={handleSearchUploader}
            />
          ) : (
            <Search
              disabled={!selectedSecondValue}
              value={selectedThirdValue}
              style={{ width: '100%' }}
              onChange={event => {
                if (event.target.value === '') {
                  clearSearchParams([`${valueOfItem?.name}`]);
                  setSelectedThirdValue(undefined);
                } else {
                  setSelectedThirdValue(event.target.value);
                }
              }}
              onSearch={() => {
                setSearchParams({
                  [`${valueOfItem?.name}`]: selectedThirdValue,
                });
              }}
              allowClear
              onClear={() => {
                clearSearchParams([`${valueOfItem?.name}`]);
                setSelectedThirdValue(undefined);
              }}
            />
          )}
        </Col>
      )}

      {selectedSecondValue === 'RangeValues' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          <SliderRange
            value={
              selectedThirdValue
                ? [
                    selectedThirdValue?.split(' ')[0],
                    selectedThirdValue?.split(' ')[1],
                  ]
                : []
            }
            onClear={() => {
              clearSearchParams([
                `${valueOfItem?.range?.from}`,
                `${valueOfItem?.range?.to}`,
              ]);
              setSelectedThirdValue(undefined);
            }}
            onChange={
              ({ valueFrom, valueTo }) => {
                setSearchParams({
                  [`${valueOfItem?.range?.from}`]:
                    valueFrom && Number(valueFrom),
                  [`${valueOfItem?.range?.to}`]: valueTo && Number(valueTo),
                });
              }

              // eslint-disable-next-line react/jsx-curly-newline
            }
            format={formatItem || ''}
            max={formatItem === '%' ? 100 : 1000}
          />
        </Col>
      )}

      {selectedSecondValue === 'Options' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          {valueOfItem.name === 'customerName' ? (
            <Select
              disabled={!selectedSecondValue}
              onSelect={value => {
                setSelectedThirdValue(value);
                setSearchParams({
                  [`${valueOfItem?.name}`]: value,
                });
              }}
              style={{ width: '100%' }}
              allowClear
              onClear={() => {
                clearSearchParams([`${valueOfItem?.name}`]);
                setSelectedThirdValue(undefined);
              }}
              value={selectedThirdValue}
              filterOption={(input, option) => {
                const verifyString = string =>
                  String(string).toLowerCase().indexOf(input.toLowerCase()) >=
                  0;
                return verifyString(option?.filterProps);
              }}
              showSearch
              options={
                valueOfItem.name === 'customerName'
                  ? mapOptions(customer?.customersData)
                  : []
              }
            />
          ) : (
            <Select
              mode={
                valueOfItem?.rules?.includes('Multiple')
                  ? 'multiple'
                  : undefined
              }
              disabled={!selectedSecondValue}
              onChange={values => {
                setSelectedThirdValue(values);
                setSearchParams({
                  [`${valueOfItem?.name}`]: Array.isArray(values)
                    ? values
                    : [values],
                });
              }}
              style={{ width: '100%' }}
              allowClear
              onClear={() => {
                clearSearchParams([`${valueOfItem?.name}`]);
                setSelectedThirdValue([]);
              }}
              value={
                valueOfItem.name === 'planTypes'
                  ? Array.isArray(selectedThirdValue)
                    ? selectedThirdValue
                    : selectedThirdValue?.split(',')
                  : selectedThirdValue
              }
              optionFilterProp={'label'}
              showSearch
              options={
                valueOfItem.name === 'apn' ||
                valueOfItem.name === 'provisionedApn'
                  ? apnData
                  : valueOfItem?.options
              }
            />
          )}
        </Col>
      )}

      {selectedSecondValue === 'DateRange' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          <SingleRangePicker
            onChange={({ startDate, endDate }) => {
              setSearchParams({
                [`${valueOfItem?.date?.start}`]: startDate,
                [`${valueOfItem?.date?.end}`]: endDate,
              });
              setSelectedThirdValue(`${startDate}/${endDate}`);
            }}
            disableEndDateLimit={valueOfItem?.disableEndDateLimit}
            value={
              selectedThirdValue
                ? [
                    selectedThirdValue.split(' ')[0],
                    selectedThirdValue.split(' ')[1],
                  ]
                : []
            }
          />
        </Col>
      )}

      {selectedSecondValue === 'Date' && (
        <Col lg={24} xl={24} xs={24}>
          <Typography>{translate(`table.${valueOfItem.name}`)}</Typography>
          <DatePicker
            showTime
            value={
              selectedThirdValue &&
              moment(new Date(selectedThirdValue), 'YYYYMMDD HH:mm:ss')
            }
            format="DD/MM/YYYY HH:mm"
            style={{ width: '100%' }}
            allowClear
            onChange={value => {
              setSelectedThirdValue(value);
              setSearchParams({
                [`${valueOfItem?.name}`]: value ? value.toISOString() : null,
              });
            }}
            renderExtraFooter={() => (
              <div
                style={{
                  display: 'flex',
                  gap: '4px',
                  flexWrap: 'wrap',
                  padding: '10px 0px 10px 0px',
                }}
              >
                {valueOfItem?.options.map((item, index) => (
                  <Tag
                    key={`${item.value}-${index}`}
                    style={{
                      cursor: 'pointer',
                      color: '#1890ff',
                      fontSize: '0.8rem',
                    }}
                    onClick={() => {
                      setSelectedThirdValue(item.value);
                      setSearchParams({
                        [`${valueOfItem?.name}`]: item.value,
                      });
                    }}
                  >
                    {item.label}
                  </Tag>
                ))}
              </div>
            )}
          />
        </Col>
      )}
    </Row>
  );
};

import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import {
  Input,
  Tooltip,
  Col,
  Tag,
  Button,
  ConfigProvider,
  Tabs,
  Space,
  Divider,
  Badge,
} from 'antd';
import { getAntLocale } from '@src/utils/locales';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import { Filters, FiltersCard } from '@components/Filters';
import { Label } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ClockCircleOutlined,
  FileSearchOutlined,
  PlusCircleOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import moment from 'moment';
import useSearchParams from '@src/hooks/useSearchParams';
import { dateFormat, formatDateTime } from '@src/utils/formatters';
import DeviceActionModal from '@src/components/DeviceActionModal/index';
import ActionTypes from '@src/utils/deviceActions';
import rolesConstants from '@src/utils/rolesConstants';
import RequestReport from '@src/components/RequestReport/RequestReport';
import SmsSendDetails from '../SmsSendDetails/index';
import SessionContext from '@src/store/SessionContext/SessionContext';

const { Search } = Input;
const { TabPane } = Tabs;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 20,
  pageSize: 20,
  pageSizeOptions: [20],
  total: 1,
};

const SmsRequest = () => {
  const {
    t: translate,
    i18n: { language },
  } = useTranslation(['sms-send']);
  const { userRoles } = useContext(SessionContext);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [createdBy, setCreatedBy] = useState('');
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [tableToShow, setTableToShow] = useState('batch');
  const [createdByValue, setCreatedByValue] = useState('');
  const [messageValue, setMessageValue] = useState('');

  const [createdAtFrom, setCreatedAtFrom] = useState();
  const [createdAtTo, setCreatedAtTo] = useState();

  const [showFilters, setShowFilters] = useState(false);
  const [idSearch, setIdSearch] = useState();
  const [idValue, setIdValue] = useState();

  const { data, mutate } = useSwr(`/service-proxy/sms-request`, {
    page: pagination?.current - 1,
    linesPerPage: pagination?.pageSize,
    createdBy,
    createdAtFrom,
    createdAtTo,
    message,
    id: idSearch,
  });

  const haveManageContract = useMemo(() => {
    const hasRole = userRoles?.['portal']?.includes(
      rolesConstants.MANAGE_SMS_CONTRACT,
    );
    return hasRole;
  }, [userRoles]);

  const { data: balanceData, mutate: mutateBalance } = useSwr(
    !haveManageContract ? `/service-proxy/sms-hiring/balance` : null,
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'createdBy',
        setState: setCreatedBy,
        inTheFilters: true,
      },
      {
        name: 'createdAtFrom',
        setState: setCreatedAtFrom,
        inTheFilters: true,
      },
      {
        name: 'createdAtTo',
        setState: setCreatedAtTo,
        inTheFilters: true,
      },
      {
        name: 'message',
        setState: setMessage,
        inTheFilters: true,
      },
      {
        name: 'id',
        setState: setIdSearch,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setMessageValue(message);
  }, [message]);

  useEffect(() => {
    setCreatedByValue(createdBy);
  }, [createdBy]);

  useEffect(() => {
    setIdValue(idSearch);
  }, [idSearch]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, []);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data.totalElements,
      }));
    }
  }, [data]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams(['page', 'linesPerPage'], 'except');
      }
    },
    [showFilters, handleClearParams],
  );

  const columnListStatus = useCallback(
    (success, row) => {
      const list = [
        {
          label: 'SUCCESS',
          color: 'success',
          value: success,
        },
        {
          label: 'PROCESSING',
          color: 'blue',
          value: row.pendingCount,
        },
        {
          label: 'ERROR',
          color: 'error',
          value: row.errorCount,
        },
      ];

      return (
        <>
          {list.map(({ label, color, value }) => (
            <Tooltip key={label} title={translate(`status.${label}`)}>
              <Tag
                icon={<ClockCircleOutlined />}
                color={color}
                style={{ cursor: 'pointer' }}
              >
                {value ?? 0}
              </Tag>
            </Tooltip>
          ))}
        </>
      );
    },
    [translate],
  );

  const messageColumn = useCallback(message => {
    return message?.length > 20 ? (
      <Tooltip title={message}>{`${message.substring(0, 20)}...`}</Tooltip>
    ) : (
      message
    );
  }, []);
  const columnDeliveryTime = useCallback(
    record => {
      if (
        record?.sendSmsRequest?.deliveryTimeWindowFrom &&
        record?.sendSmsRequest?.deliveryTimeWindowTo &&
        record?.sendSmsRequest?.deliveryTimeWindowDays
      ) {
        return (
          <Tag>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <div>
                  {record.sendSmsRequest?.deliveryTimeWindowFrom} -{' '}
                  {record.sendSmsRequest?.deliveryTimeWindowTo}
                </div>
              </div>

              <Tooltip
                title={
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {record?.sendSmsRequest?.deliveryTimeWindowDays.map(day => (
                      <span key={day}>
                        {translate(`days.${day?.toLowerCase()}`)}
                      </span>
                    ))}
                  </div>
                }
              >
                <Button type="link" color={'#5867dd'}>
                  {translate('deliveryTimeWindowDays')}
                </Button>
              </Tooltip>
            </div>
          </Tag>
        );
      }

      return record.sendSmsRequest?.sendAt ? (
        <Tag>{formatDateTime(record.sendSmsRequest?.sendAt)}</Tag>
      ) : (
        ''
      );
    },
    [translate],
  );
  const columns = useMemo(
    () => [
      {
        title: 'ID',
        dataIndex: 'id',
        align: 'center',
        render: (_, record) => record.sendSmsRequest?.id,
      },
      {
        title: translate('table.from'),
        dataIndex: 'from',
        align: 'center',
        render: (_, record) => record.sendSmsRequest?.from,
      },
      {
        title: translate('table.message'),
        dataIndex: 'message',
        align: 'center',
        render: (_, record) => messageColumn(record.sendSmsRequest?.message),
      },
      {
        title: translate('table.numSms'),
        dataIndex: 'successCount',
        align: 'center',
        render: (value, record) => columnListStatus(value, record),
      },

      {
        title: translate('table.status'),
        dataIndex: 'status',
        align: 'center',
        render: (_, record) =>
          translate(`status-type.${record.sendSmsRequest?.status}`),
      },
      {
        title: translate('table.type'),
        dataIndex: 'flash',
        align: 'center',
        render: (_, record) =>
          record.sendSmsRequest?.flash
            ? translate('table.flash')
            : translate('table.normal'),
      },
      {
        title: translate('table.deliveryTime'),
        dataIndex: 'deliveryTime',
        align: 'center',
        render: (_, record) => columnDeliveryTime(record),
      },
      {
        title: translate('table.validityPeriod'),
        dataIndex: 'validityMinutes',
        align: 'center',
        render: (_, record) =>
          typeof record.sendSmsRequest?.validityMinutes === 'number'
            ? record.sendSmsRequest?.validityMinutes > 0
              ? record.sendSmsRequest?.validityMinutes / 60
              : '0'
            : '',
      },
      {
        title: translate('table.createdBy'),
        dataIndex: 'createdBy',
        align: 'center',
        render: (_, record) => record.sendSmsRequest?.createdBy,
      },
      {
        title: translate('table.createdAt'),
        dataIndex: 'createdAt',
        align: 'center',
        render: (_, record) =>
          record.sendSmsRequest?.createdAt &&
          dateFormat(record.sendSmsRequest?.createdAt),
      },
      {
        title: translate('columns.actions'),
        dataIndex: 'actions',
        align: 'center',
        render: (value, record) => (
          <Button
            type="link"
            color={'#5867dd'}
            icon={<FileSearchOutlined />}
            onClick={() => {
              setTableToShow('one');
              handleSetSearchParams({
                sendSmsRequestId: record?.sendSmsRequest?.id,
              });
            }}
          />
        ),
      },
    ],
    [
      translate,
      columnListStatus,
      messageColumn,
      columnDeliveryTime,
      handleSetSearchParams,
    ],
  );

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      handleSetSearchParams({
        createdAtFrom: initialDate,
        createdAtTo: endDate,
      });
    },
    [handleSetSearchParams],
  );
  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };
  const handleTabsChange = useCallback(
    key => {
      setTableToShow(key);
      handleClearParams();
      setPagination(oldPagination => ({
        ...oldPagination,
        current: 1,
      }));
    },
    [handleClearParams],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: translate('table.createdBy'),
        item: (
          <Search
            onSearch={value => handleSetSearchParams({ createdBy: value })}
            onChange={({ target: { value } }) => setCreatedByValue(value)}
            value={createdByValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: 'ID',
        item: (
          <Search
            onSearch={value => handleSetSearchParams({ id: value })}
            onChange={({ target: { value } }) => setIdValue(value)}
            value={idValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: translate('table.message'),
        item: (
          <Search
            onSearch={value => handleSetSearchParams({ message: value })}
            onChange={({ target: { value } }) => setMessageValue(value)}
            value={messageValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: translate('table.createdAt'),
        item: (
          <ConfigProvider locale={getAntLocale(language)}>
            <QuickRangePicker
              onChange={handleDateChange}
              defaultValue={
                createdAtFrom
                  ? [
                      moment(createdAtFrom, 'YYYYMMDD'),
                      moment(createdAtTo, 'YYYYMMDD'),
                    ]
                  : undefined
              }
              style={{ width: '100%' }}
            />
          </ConfigProvider>
        ),
      },
    ],
    [
      translate,
      handleSetSearchParams,
      createdAtFrom,
      createdAtTo,
      handleDateChange,
      language,
      createdByValue,
      messageValue,
      idValue,
    ],
  );

  return (
    <>
      <Tabs
        type="card"
        defaultActiveKey="receive"
        activeKey={tableToShow}
        onChange={handleTabsChange}
        style={{ background: '#ffffff' }}
      >
        <TabPane key="batch" tab={translate('tabs.batch')} />

        <TabPane key="one" tab={translate('tabs.one')} />
      </Tabs>
      {tableToShow === 'batch' ? (
        <Table
          title={
            <>
              {translate('title.main')}
              {data?.content && (
                <span
                  style={{ fontSize: '0.9rem', opacity: 0.6, marginRight: 4 }}
                >
                  {` (${data?.content?.length} ${translate('of', {
                    total: pagination.total,
                  })})`}
                </span>
              )}
              <Filters
                showFilters={showFilters}
                setShowFilters={handleShowFilters}
              />
            </>
          }
          columns={columns}
          data={data?.content}
          loading={!data?.content}
          tableKey="sms-send"
          pagination={pagination}
          onChange={handleTableChange}
          mainExtraAction={
            !haveManageContract && (
              <Space>
                <Divider orientation="center" type="vertical" />
                <Tooltip title={translate('hire.redirectToPlans')}>
                  <Badge
                    count={balanceData?.ppu?.status === 'active' ? 'PPU' : ''}
                    style={{
                      backgroundColor: '#52c41a',
                    }}
                  >
                    <Button
                      onClick={() => navigate(`/sms/contracts-sms`)}
                      type="primary"
                    >
                      <div style={{ display: 'flex', gridGap: '5px' }}>
                        <ShoppingCartOutlined />
                        <p style={{ margin: 0 }}>
                          {(balanceData?.smsPackage?.totalSMS ||
                            balanceData?.smsPackage?.totalSMS > 0) &&
                            `${
                              balanceData?.smsPackage?.availableSMS || 0
                            } ${translate('hire.of')} ${
                              balanceData?.smsPackage?.totalSMS
                            } SMS`}
                        </p>

                        <p style={{ margin: 0 }}>
                          {(!balanceData?.smsPackage?.totalSMS ||
                            balanceData?.smsPackage?.totalSMS < 0) &&
                            balanceData?.ppu?.status !== 'active' &&
                            `${translate('hire.newPlan')}`}
                        </p>
                      </div>
                    </Button>
                  </Badge>
                </Tooltip>
              </Space>
            )
          }
          extraActions={
            <>
              <Tooltip placement="top" title={translate('title.newSmsSend')}>
                <Button
                  icon={<PlusCircleOutlined />}
                  size="large"
                  type="link"
                  onClick={() => setVisible(true)}
                />
              </Tooltip>
              <RequestReport
                items={[
                  {
                    role: rolesConstants.REQUEST_REPORT,
                    path: '/service-proxy/sms-request/batch/report',
                    type: 'SMS_REQUESTS',
                    filters: {
                      createdBy,
                      createdAtFrom,
                      createdAtTo,
                      message,
                    },
                    isParams: true,
                  },
                ]}
              />
            </>
          }
          extraFilters={
            showFilters && (
              <FiltersCard>
                {tableFilters?.map(
                  ({ col: { lg, xl, xs }, label, item, visible }) =>
                    visible && (
                      <Col key={label} lg={lg} xl={xl} xs={xs}>
                        {label && (
                          <div>
                            <Label color={'#575962'} htmlFor={label}>
                              {label}
                            </Label>
                          </div>
                        )}
                        {item}
                      </Col>
                    ),
                )}
              </FiltersCard>
            )
          }
        />
      ) : (
        <SmsSendDetails
          setTableToShow={setTableToShow}
          haveManageContract={haveManageContract}
          balanceSmsData={balanceData}
        />
      )}
      <DeviceActionModal
        actionKey={ActionTypes.SEND_SMS}
        visible={visible}
        onClose={() => {
          mutate('/service-proxy/sms-request');
          if (!haveManageContract) {
            mutateBalance();
          }
          setVisible(false);
        }}
      />
    </>
  );
};

export default SmsRequest;

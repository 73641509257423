import React, { useContext, useMemo } from 'react';
import SessionContext from '@src/store/SessionContext/SessionContext';

const Logo = ({ ...props }) => {
  const { logoImgName } = useContext(SessionContext);

  const imgSrc = useMemo(() => {
    const { hostname } = window.location;
    return `${
      hostname === 'localhost' ? process.env.REACT_APP_HOM_URL : ''
    }/images/${logoImgName ?? hostname}`;
  }, [logoImgName]);

  return <img src={imgSrc} width={'100%'} {...props} />;
};

export default Logo;

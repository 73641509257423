import React, { useEffect } from 'react';
import './styles.css';
import { Logo } from '../ui/index';

const PortalSpinner = ({ children }) => {
  useEffect(() => {
    const documentOverflow = overflow =>
      (document.body.style.overflow = overflow);
    documentOverflow('hidden');

    return () => {
      documentOverflow('auto');
    };
  }, []);

  return (
    <div className="logo-container">
      <Logo id="breathing-img" />
      {children}
    </div>
  );
};

export default PortalSpinner;

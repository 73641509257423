import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from '@components/ui';
import { useTranslation } from 'react-i18next';
import { Empty, Radio, Skeleton } from 'antd';
import { formatBRNumber } from '@utils/formatters';
import { Pie } from '@ant-design/plots';
import useSwr from '@hooks/useSwr';

import './styles.less';

const TotalLines = ({ isAllcom, filters }) => {
  const { t } = useTranslation('dashboard');

  const [connectedOperator, setConnectedOperator] = useState(false);
  const [orderedData, setOrderedData] = useState();

  const { data: connectedBySupplies } = useSwr(
    `/service-proxy/inventory/MOT/dashboards/count-by-operator`,
    {
      ...filters,
    },
    {
      method: 'post',
    },
  );

  const { data: connectedByOperator } = useSwr(
    `/service-proxy/inventory/MOT/dashboards/count-by-connected-operator`,
    {
      ...filters,
    },
    {
      method: 'post',
    },
  );

  const order = useCallback(
    data => data?.sort((a, b) => a.quantity - b.quantity),
    [],
  );

  useEffect(() => {
    if (connectedOperator) {
      order(connectedByOperator);
      setOrderedData(connectedByOperator);
    } else {
      order(connectedBySupplies);
      setOrderedData(connectedBySupplies);
    }
  }, [connectedByOperator, connectedBySupplies, connectedOperator, order]);

  const pieData = useMemo(
    () =>
      orderedData?.map(s => {
        const operatorName = s.value === null ? 'OFFLINE' : s.value;
        return {
          type: operatorName,
          value: parseInt(s.count),
        };
      }),
    [orderedData],
  );

  const config = useMemo(
    () => ({
      data: pieData,
      angleField: 'value',
      colorField: 'type',
      innerRadius: 0.7,
      meta: {
        value: {
          formatter: value => formatBRNumber(value),
        },
      },
      label: false,
      interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    }),
    [pieData],
  );

  const PieEl = useCallback(
    () => (
      <Pie
        style={{ height: isAllcom ? '500px' : '352px', padding: '16px' }}
        {...config}
      />
    ),
    [config, isAllcom],
  );

  const handleTabFilterChange = useCallback(
    ({ target: { value } }) => setConnectedOperator(value),
    [],
  );

  return (
    <Card
      loading={!pieData}
      title={
        <div className="card-title">
          {t('total-lines.title')}
          <Radio.Group defaultValue={false} onChange={handleTabFilterChange}>
            <Radio.Button value={false}>
              {t(`total-lines.home-operator`)}
            </Radio.Button>
            <Radio.Button value={true}>
              {t(`total-lines.connected-operator`)}
            </Radio.Button>
          </Radio.Group>
        </div>
      }
      className="total-lines-card"
    >
      {pieData?.length === 0 ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Skeleton loading={!connectedOperator && !connectedBySupplies}>
          <PieEl />
        </Skeleton>
      )}
    </Card>
  );
};

export default TotalLines;

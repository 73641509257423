import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Form, Skeleton, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import useSwr from '@hooks/useSwr';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import PermissionsCard from '@src/components/PermissionsCard/PermissionsCard';
import rolesConstants from '@src/utils/rolesConstants';

const { Paragraph } = Typography;
const { Item } = Form;

const CustomerPermissionsForm = ({
  form,
  parentIdSelected,
  setSelectedRoles,
  customerTypeWillCreated = 'CUSTOMER',
}) => {
  const { t } = useTranslation(['customers', 'permissions']);
  const { keycloak } = useContext(KeycloakContext);
  const defaultParentId = keycloak.idTokenParsed?.customerId;

  const { data } = useSwr(
    `/customers/${parentIdSelected ?? defaultParentId}/roles/`,
    {
      customerType: customerTypeWillCreated,
    },
  );

  const [loading, setLoading] = useState(false);

  const groupedData = useMemo(
    () =>
      data &&
      Object.entries(
        data.reduce(
          (groups, item) => ({
            ...groups,
            [item.category]: [...(groups[item.category] || []), item],
          }),
          {},
        ),
      ),
    [data],
  );

  useEffect(() => {
    setLoading(true);
    if (data) setLoading(false);
  }, [data]);

  // permissions that cannot be passed on
  const permissionToRemove = useMemo(
    () => [
      {
        category: 'assets',
        role: rolesConstants.SUSPENSION_WITHDRAWAL,
      },
      {
        category: 'general',
        role: rolesConstants.INVENT_IS_SUSPENDABLE,
      },
      {
        category: 'sms',
        role: rolesConstants.SMS_CONTRACT,
      },
      {
        category: 'sms',
        role: rolesConstants.SMS_HIRING,
      },
      {
        category: 'sms',
        role: rolesConstants.SEND_SMS,
      },
      {
        category: 'sms',
        role: rolesConstants.SMS_SENDERS,
      },
      {
        category: 'sms',
        role: rolesConstants.MANAGE_SMS,
      },
      {
        category: 'sms',
        role: rolesConstants.MANAGE_SMS_CONTRACT,
      },
    ],
    [],
  );

  const groupedRoles = useMemo(() => {
    let newGroupedRoles = groupedData;
    if (defaultParentId !== 10000) {
      permissionToRemove?.forEach(({ category, role }) => {
        newGroupedRoles = newGroupedRoles
          ?.map(([groupedCategory, permissions]) => {
            if (category === groupedCategory) {
              const filteredPermissions = permissions?.filter(
                permission => permission.name !== role,
              );
              return filteredPermissions?.length > 0
                ? [groupedCategory, filteredPermissions]
                : null;
            }
            return [groupedCategory, permissions];
          })
          .filter(group => group !== null);
      });
    }

    return newGroupedRoles;
  }, [groupedData, permissionToRemove, defaultParentId]);

  return (
    <Skeleton loading={loading} active>
      <Form colon={false} form={form} layout="vertical">
        <Paragraph style={{ padding: '10px 0' }}>
          {t('form.help.permissions')}
        </Paragraph>

        <Item name="roles">
          <PermissionsCard
            groupedRoles={groupedRoles}
            selectedRoles={form.getFieldValue('roles')}
            setSelectedRoles={setSelectedRoles}
          />
        </Item>
      </Form>
    </Skeleton>
  );
};

export default CustomerPermissionsForm;

const ActionTypes = {
  ACTIVATION_MULTI_CONNECT: 0,
  ACTIVATION: 1,
  NETWORK_RESET: 3,
  SIMCARD_RESET: 4,
  OPERATOR_RESTRICTION: 5,
  PRIORITY_OPERATOR: 6,
  TRANSFER: 1234,
  NICKNAME_CHANGE: 16,
  PROVISIONAL_SUSPENSION: 9,
  CLIENT_ACTIVATION: 11,
  SMS_BLOCK: 12,
  SMS_UNBLOCK: 13,
  NETWORK_BLOCK: 20,
  NETWORK_UNLOCK: 21,
  NETWORK_BLOCK_UNLOCK: 91,
  IMEI_LOCK: 22,
  IMEI_UNLOCK: 23,
  IMEI_LOCK_UNLOCK: 99,
  SUSPENSION: 24,
  WITHDRAWAL: 25,
  SUSPENSION_WITHDRAWAL: 125,
  SUSPENSION_CANCELL: 40,
  PLASTIC_EXCHANGE: 26,
  MOT_DEACTIVATION: 27,
  MSISDN_EXCHANGE: 28,
  MANUAL_ACTIVATION: 29,
  FORCE_REACTIVATION: 30,
  FINANCIAL_BLOCK: 31,
  FINANCIAL_UNLOCK: 32,
  ADD_CREDIT_TO_SIMCARD: 33,
  BROKER_CANCELLATION: 41,
  BROKER_CANCELLATION_WAIVER: 42,
  BROKER_SUBSTITUTION: 43,
  MOT_OPERATOR_CHANGE: 44,
  SEND_SMS: 46,
  HLR_REPROG: 45,
  BAIT_RENEWAL: 49,
  ALLOWANCE_PLAN_RENEWAL: 48,
};

export const LorawanActionTypes = {
  TRANSFER: -1,
  ACTIVATION: 0,
  DEACTIVATION: 1,
  IMPORT: 2,
  UPLINK_BLOCKING: 10,
  UPLINK_UNBLOCKING: 11,
  DOWNLINK_BLOCKING: 12,
  DOWNLINK_UNBLOCKING: 13,
  CHANGE_DESCRIPTION: 14,
  CHANGE_TAGS: 15,
  REFERRAL: 16,
  FORWARD_DELETION: 17,
};

export const actionTypeOptions = t => {
  return [
    {
      label: t('device-actions:labels.activation-multi-connect'),
      value: 0,
    },
    {
      label: t('device-actions:labels.activation-mot'),
      value: 1,
    },
    {
      label: t('device-actions:labels.network-reset'),
      value: 3,
    },
    {
      label: t('device-actions:labels.simcard-reset'),
      value: 4,
    },
    {
      label: t('device-actions:labels.operator-restriction'),
      value: 5,
    },
    {
      label: t('device-actions:labels.priority-operator'),
      value: 6,
    },
    {
      label: t('device-actions:labels.use-blocking'),
      value: 7,
    },
    {
      label: t('device-actions:labels.plastic-change'),
      value: 8,
    },
    {
      label: t('device-actions:labels.provisional-suspension'),
      value: 9,
    },
    {
      label: t('device-actions:labels.client-activation'),
      value: 11,
    },
    {
      label: t('device-actions:labels.sms-block'),
      value: 12,
    },
    {
      label: t('device-actions:labels.sms-unlock'),
      value: 13,
    },
    {
      label: t('device-actions:labels.nickname-change'),
      value: 16,
    },
    {
      label: t('device-actions:labels.use-unlock'),
      value: 17,
    },
    {
      label: t('device-actions:labels.network-block'),
      value: 20,
    },
    {
      label: t('device-actions:labels.network-unlock'),
      value: 21,
    },
    {
      label: t('device-actions:labels.imei-lock'),
      value: 22,
    },
    {
      label: t('device-actions:labels.imei-unlock'),
      value: 23,
    },
    {
      label: t('device-actions:labels.suspension'),
      value: 24,
    },
    {
      label: t('device-actions:labels.withdrawal'),
      value: 25,
    },
    {
      label: t('device-actions:labels.plastic-exchange'),
      value: 26,
    },
    {
      label: t('device-actions:labels.mot-deactivation'),
      value: 27,
    },
    {
      label: t('device-actions:labels.msisdn-exchange'),
      value: 28,
    },
    {
      label: t('device-actions:labels.manual-activation'),
      value: 29,
    },
    {
      label: t('device-actions:labels.force-reactivation'),
      value: 30,
    },
    {
      label: t('device-actions:labels.financial-block'),
      value: 31,
    },
    {
      label: t('device-actions:labels.financial-unlock'),
      value: 32,
    },
    {
      label: t('device-actions:labels.add-credit'),
      value: 33,
    },
    {
      label: t('device-actions:labels.arqia-network-reset'),
      value: 35,
    },
    {
      label: t('device-actions:labels.broker-cancellation'),
      value: 41,
    },
    {
      label: t('device-actions:labels.broker-cancellation-waiver'),
      value: 42,
    },
    {
      label: t('device-actions:labels.broker-substitution'),
      value: 43,
    },
    {
      label: t('device-actions:labels.mot-operator-change'),
      value: 44,
    },
    {
      label: t('device-actions:labels.hlr-reprog'),
      value: 45,
    },
    {
      label: t('device-actions:labels.sms-send'),
      value: 46,
    },
  ];
};

export default ActionTypes;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Input,
  Col,
  ConfigProvider,
  Tooltip,
  Select,
  Button,
  Space,
  Divider,
  Badge,
} from 'antd';

import { useTranslation } from 'react-i18next';
import {
  InfoCircleOutlined,
  PlusCircleOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/pt-br';
import useSwr from '@src/hooks/useSwr';
import Table from '@components/Table';
import { Label } from '@components/ui';
import { dateFormat } from '@src/utils/formatters';
import useSearchParams from '@src/hooks/useSearchParams';
import { FiltersCard, Filters } from '@src/components/Filters/index';
import QuickRangePicker from '@src/components/DatePicker/QuickRangePicker/QuickRangePicker';
import moment from 'moment';
import ptBR from 'antd/es/locale/pt_BR';
import ActionTypes from '@src/utils/deviceActions';
import DeviceActionModal from '@src/components/DeviceActionModal/index';
import RequestReport from '@src/components/RequestReport/RequestReport';
import rolesConstants from '@src/utils/rolesConstants';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 20,
  pageSize: 20,
  pageSizeOptions: [20],
  total: 1,
};
const SmsSendDetails = ({
  setTableToShow,
  balanceSmsData,
  haveManageContract,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [msisdn, setMsisdn] = useState('');
  const [msisdnValue, setMsisdnValue] = useState('');
  const [statusValue, setStatusValue] = useState();
  const [sendSmsRequestId, setSendSmsRequestId] = useState();
  const [sendSmsRequestIdValue, setSendSmsRequestIdValue] = useState();
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [sentAtFrom, setSentAtFrom] = useState();
  const [sentAtTo, setSentAtTo] = useState();
  const { t } = useTranslation('sms-send');
  const navigate = useNavigate();
  const { data, mutate } = useSwr(`/service-proxy/sms`, {
    page: pagination?.current - 1,
    linesPerPage: pagination?.pageSize,
    sendSmsRequestId,
    msisdn,
    sentAtFrom,
    sentAtTo,
    status: statusValue?.toUpperCase(),
  });
  const paramsAttributes = useMemo(
    () => [
      {
        name: 'msisdn',
        setState: setMsisdn,
        inTheFilters: true,
      },
      {
        name: 'sentAtFrom',
        setState: setSentAtFrom,
        inTheFilters: true,
      },
      {
        name: 'sentAtTo',
        setState: setSentAtTo,
        inTheFilters: true,
      },
      {
        name: 'sendSmsRequestId',
        setState: setSendSmsRequestId,
        inTheFilters: true,
      },
      {
        name: 'status',
        setState: setStatusValue,
        inTheFilters: false,
      },
    ],
    [],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  const smsDescriptionLink = useCallback(
    sendSmsRequestId => (
      <Tooltip title={t('view-sms')}>
        <Button
          type="link"
          onClick={() => {
            handleSetSearchParams({ id: sendSmsRequestId });
            setTableToShow('batch');
          }}
        >
          {sendSmsRequestId}
        </Button>
      </Tooltip>
    ),
    [t, handleSetSearchParams, setTableToShow],
  );

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: t('table.sendSmsRequestId'),
        dataIndex: 'sendSmsRequestId',
        align: 'center',
        render: smsDescriptionLink,
      },
      { title: t('table.msisdn'), dataIndex: 'msisdn' },
      {
        title: t('table.status'),
        dataIndex: 'status',
        render: (status, record) =>
          record.statusDetailed ? (
            <Tooltip title={t(`status-type.details.${record.statusDetailed}`)}>
              <span style={{ marginRight: '3px' }}>
                {t(`status-type.${status}`)}
              </span>
              <InfoCircleOutlined />
            </Tooltip>
          ) : (
            <span>{t(`status-type.${status}`)}</span>
          ),
      },

      {
        title: t('table.createdAt'),
        dataIndex: 'createdAt',
        render: value => value && dateFormat(value),
      },
      {
        title: t('table.sentAt'),
        dataIndex: 'sentAt',
        render: value => value && dateFormat(value),
      },
      {
        title: t('table.createdBy'),
        dataIndex: 'createdBy',
      },
    ];

    return defaultColumns;
  }, [t, smsDescriptionLink]);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const handleDateChange = useCallback(
    ({ initialDate, endDate }) => {
      handleSetSearchParams({ sentAtFrom: initialDate, sentAtTo: endDate });
    },
    [handleSetSearchParams],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.msisdn'),
        item: (
          <Search
            onSearch={value => handleSetSearchParams({ msisdn: value })}
            onChange={({ target: { value } }) => setMsisdnValue(value)}
            value={msisdnValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.sendSmsRequestId'),
        item: (
          <Search
            onSearch={value =>
              handleSetSearchParams({ sendSmsRequestId: value })
            }
            onChange={({ target: { value } }) =>
              setSendSmsRequestIdValue(value)
            }
            value={sendSmsRequestIdValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.sentAtTo'),
        item: (
          <ConfigProvider locale={ptBR}>
            <QuickRangePicker
              onChange={handleDateChange}
              defaultValue={
                sentAtFrom
                  ? [
                      moment(sentAtFrom, 'YYYYMMDD'),
                      moment(sentAtTo, 'YYYYMMDD'),
                    ]
                  : undefined
              }
              style={{ width: '100%' }}
            />
          </ConfigProvider>
        ),
      },
      {
        visible: true,
        col: {
          lg: 10,
          xl: 8,
          xs: 24,
        },
        label: t('table.status'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={[
              {
                label: t('status-type.PENDING'),
                value: 'PENDING',
              },
              {
                label: t('status-type.UNDELIVERABLE'),
                value: 'UNDELIVERABLE',
              },
              {
                label: t('status-type.DELIVERED'),
                value: 'DELIVERED',
              },
              {
                label: t('status-type.EXPIRED'),
                value: 'EXPIRED',
              },
              {
                label: t('status-type.REJECTED'),
                value: 'REJECTED',
              },
            ]}
            style={{ width: '100%' }}
            allowClear
            showSearch
            value={statusValue}
            onChange={value => handleSetSearchParams({ status: value })}
          />
        ),
      },
    ],
    [
      handleSetSearchParams,
      t,
      sentAtFrom,
      handleDateChange,
      sentAtTo,
      msisdnValue,
      statusValue,
      sendSmsRequestIdValue,
    ],
  );

  const handleTableChange = paginationObj => {
    if (paginationObj) {
      setPagination(() => ({
        ...paginationObj,
        linesPerPage: paginationObj.pageSize,
      }));
    }
  };

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, []);

  useEffect(() => {
    if (data) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: data?.totalElements,
      }));
    }
  }, [data]);

  useEffect(() => {
    setMsisdnValue(msisdn);
  }, [msisdn]);

  useEffect(() => {
    setSendSmsRequestIdValue(sendSmsRequestId);
  }, [sendSmsRequestId]);

  return (
    <>
      <Table
        title={
          <div style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
            {t('title.details')}
            {data?.content && (
              <span
                style={{ fontSize: '0.9rem', opacity: 0.6, marginRight: 4 }}
              >
                {` (${data?.content?.length} ${t('of', {
                  total: pagination.total,
                })})`}
              </span>
            )}
            <Filters
              showFilters={showFilters}
              setShowFilters={handleShowFilters}
              style={{ padding: 0 }}
            />
          </div>
        }
        columns={columns}
        data={data?.content}
        loading={!data}
        pagination={pagination}
        onChange={handleTableChange}
        mainExtraAction={
          !haveManageContract && (
            <Space>
              <Divider orientation="center" type="vertical" />
              <Tooltip title={t('hire.redirectToPlans')}>
                <Badge
                  count={balanceSmsData?.ppu?.status === 'active' ? 'PPU' : ''}
                  style={{
                    backgroundColor: '#52c41a',
                  }}
                >
                  <Button
                    onClick={() => navigate(`/sms/contracts-sms`)}
                    type="primary"
                  >
                    <div style={{ display: 'flex', gridGap: '5px' }}>
                      <ShoppingCartOutlined />
                      <p style={{ margin: 0 }}>
                        {(balanceSmsData?.smsPackage?.totalSMS ||
                          balanceSmsData?.smsPackage?.totalSMS > 0) &&
                          `${balanceSmsData?.smsPackage?.availableSMS || 0} ${t(
                            'hire.of',
                          )} ${balanceSmsData?.smsPackage?.totalSMS} SMS`}
                      </p>

                      <p style={{ margin: 0 }}>
                        {(!balanceSmsData?.smsPackage?.totalSMS ||
                          balanceSmsData?.smsPackage?.totalSMS < 0) &&
                          balanceSmsData?.ppu?.status !== 'active' &&
                          `${t('hire.newPlan')}`}
                      </p>
                    </div>
                  </Button>
                </Badge>
              </Tooltip>
            </Space>
          )
        }
        extraActions={
          <>
            <Tooltip placement="top" title={t('title.newSmsSend')}>
              <Button
                icon={<PlusCircleOutlined />}
                size="large"
                type="link"
                onClick={() => setVisible(true)}
              />
            </Tooltip>
            <RequestReport
              items={[
                {
                  role: rolesConstants.REQUEST_REPORT,
                  path: '/service-proxy/sms/unitary/report',
                  type: 'SMS',
                  filters: {
                    sendSmsRequestId,
                    msisdn,
                    sentAtFrom,
                    sentAtTo,
                  },
                  isParams: true,
                },
              ]}
            />
          </>
        }
        tableKey={'sms-send-details'}
        extraFilters={
          showFilters && (
            <>
              <FiltersCard>
                {tableFilters?.map(
                  ({ col: { lg, xl, xs }, label, item, visible }) =>
                    visible && (
                      <Col key={label} lg={lg} xl={xl} xs={xs}>
                        {label && (
                          <div>
                            <Label color={'#575962'} htmlFor={label}>
                              {label}
                            </Label>
                          </div>
                        )}
                        {item}
                      </Col>
                    ),
                )}
              </FiltersCard>
            </>
          )
        }
      />

      <DeviceActionModal
        actionKey={ActionTypes.SEND_SMS}
        visible={visible}
        onClose={() => {
          mutate('/service-proxy/sms');
          setVisible(false);
        }}
      />
    </>
  );
};

export default SmsSendDetails;
